.card {
    transition: all 0.3s ease;
  }
  
  .card:hover {
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
  }
  
  .btn-primary {
    transition: all 0.2s ease-in-out;
  }
  
  .btn-primary:hover {
    transform: scale(1.05);
    box-shadow: 0px 4px 10px rgba(0, 123, 255, 0.3);
  }
  