.card {
    border-radius: 10px;
  }
  
  .btn-outline-primary,
  .btn-outline-success {
    transition: all 0.2s ease-in-out;
  }
  
  .btn-outline-primary:hover,
  .btn-outline-success:hover {
    transform: scale(1.05);
    box-shadow: 0px 4px 10px rgba(0, 123, 255, 0.3);
  }
  